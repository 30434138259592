// TODO: hooks
import { Outlet } from 'react-router-dom'
// NOTE: components
import NavBar from './navbar/NavBar'

interface LayoutProps {
	links: any[]
	home: string
	icon?: string
}

const Layout = ({ links, home, icon }: LayoutProps) => {
	return (
		// TODO: min-h-screen min-h-svh
		<div className="flex w-full">
				<NavBar links={links} home={home} icon={icon} />
			<Outlet />
		</div>
	)
}

export default Layout