import React, { ReactNode, CSSProperties, KeyboardEvent } from 'react'

interface CardProps {
	className?: string
	style?: CSSProperties
	children: ReactNode
	onClick?: () => void
	secondary?: boolean
}

const Card = ({ className = '', style, children, onClick, secondary = false }: CardProps) => {
	// TODO: change shadow
	const baseStyles = 'rounded-lg bg-white'
	const secondaryStyles = 'border-2 border-slate-100 shadow-sm bg-slate-50 rounded-md'

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (onClick && (e.key === 'Enter' || e.key === ' ')) {
			e.preventDefault()
			onClick()
		}
	}

	return (
		<div
			className={`h-fit w-fit ${secondary ? secondaryStyles : baseStyles} ${className}`}
			style={{
				...(!secondary && { boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px' }),
				cursor: onClick ? 'pointer' : 'auto',
				...style
			}}
			onClick={onClick}
			role={onClick ? 'button' : undefined}
			tabIndex={onClick ? 0 : undefined}
			onKeyDown={handleKeyDown}
		>
			{children}
		</div>
	)
}

export default Card
