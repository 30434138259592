// NOTE: native
import React from 'react'
import ReactDOM from 'react-dom/client'
// NOTE: hooks
import { BrowserRouter } from 'react-router-dom'
import { AuthContextProvider } from './context/AuthContext'
// NOTE: components
import App from './App'
import Toaster from './components/ui/Sonner'
// NOTE: assets
import './assets/css/index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Toaster closeButton richColors />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>
)
