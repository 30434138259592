import ReactLoading from 'react-loading'

interface LoadingProps {
	size?: number
}

const Loading = ({ size }: LoadingProps) => (
	<div
		className="m-auto flex items-center justify-center"
		style={{ width: '100%', height: '100%' }}
	>
		<ReactLoading type={'bubbles'} height={size ?? 100} width={size ?? 100} color={'#00CC66'} />
	</div>
)

export default Loading
